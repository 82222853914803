import React from "react";
import style from "./App.module.scss";
import { AppBar, TextField, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import crypto from "crypto";
import copy from "copy-to-clipboard";

class App extends React.Component {
  state = {
    enValue: "",
    deValue: "",
    keyStr: "zG2nSeEfSHfvTCHy5LCcqtBbQehKNLXn",
    openSnackBar: false,
    canCopy: false,
  };

  handleInputChange = (event: any, key: string) => {
    this.setState({ [key]: event.target.value });
  };
  render() {
    return (
      <div className={style.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className="menu-button"
              color="inherit"
              aria-label="menu"
            >
              <i className="ri-menu-line"></i>
            </IconButton>
            <Typography variant="h6" className="title">
              刺猬猫解密
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={style.contentContainer}>
          <div className={style.decryptContainer}>
            <TextField
              label="加密的字符串"
              className={style.decryptField}
              fullWidth
              multiline
              rows={12}
              variant="outlined"
              value={this.state.enValue}
              onChange={(e) => this.handleInputChange(e, "enValue")}
            />
            <TextField
              label="解密密钥"
              className={style.keyField}
              fullWidth
              variant="outlined"
              value={this.state.keyStr}
              onChange={(e) => this.handleInputChange(e, "keyStr")}
            />
            <Button
              variant="outlined"
              color="primary"
              className={style.decryptButton}
              onClick={this.decryptStr}
            >
              解密
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={style.decryptButton}
              onClick={this.copyStr}
              disabled={!this.state.canCopy}
            >
              复制
            </Button>
          </div>
          <TextField
            label="解密后字符"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            className={style.encryptdField}
            multiline
            rows={36}
            variant="outlined"
            value={this.state.deValue}
          />
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => this.setState({ openSnackBar: false })}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ openSnackBar: false })}
            severity="success"
          >
            解密结果已复制到剪切板！
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }

  decryptStr = () => {
    let enStr = this.state.enValue;
    let key = crypto.createHash("sha256").update(this.state.keyStr).digest();
    let decipher = crypto.createDecipheriv(
      "aes-256-cbc",
      key,
      new Uint8Array(16)
    );
    decipher.setAutoPadding(false);
    let decrypted = decipher.update(enStr, "base64", "utf8");
    if (this.state.keyStr === "zG2nSeEfSHfvTCHy5LCcqtBbQehKNLXn") {
      let lastIndex = decrypted.lastIndexOf("}");
      decrypted = decrypted.substr(0, lastIndex + 1);
      decrypted = JSON.stringify(JSON.parse(decrypted), null, 2);
    }
    this.setState({
      deValue: decrypted,
      canCopy: true,
    });
  };

  copyStr = () => {
    copy(this.state.deValue, {});
    this.setState({ openSnackBar: true });
  };
}

export default App;
